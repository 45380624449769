import { createContext, useReducer } from 'react'
import ECReducer from './ECReducer'

const ECContext = createContext()

export const AbaturoffProvider = ({ children }) => {
  const initialState = {
     loading: false,
     showNewTransporterDialog: {showDialog: false},
     showNewTransporterContractDialog: {showDialog: false},
     showNewClientContractDialog: {showDialog: false},

     clientAdditionalExpensesSum: 0,
     transporter1AdditionalExpensesSum: 0,

  }

  const [state, dispatch] = useReducer(ECReducer, initialState)

  return (
    <ECContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </ECContext.Provider>
  )
}

export default ECContext
