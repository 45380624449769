const ECReducer = (state, action) => {

console.log(`ECReducer. Action:`)
console.log(action)

  switch (action.type) {
    case 'SET_SHOW_INSPECTION_DIALOG':
      return {
        ...state,
        showInspectionDialog: true
      }
    case 'SET_SHOW_NEW_TRANSPORTER_DIALOG':
      return {
        ...state,
        showNewTransporterDialog: action.payload
      }
    case 'SET_SHOW_NEW_TRANSPORTER_CONTRACT_DIALOG':
      return {
        ...state,
        showNewTransporterContractDialog: action.payload
      }
    case 'SET_SHOW_NEW_CLIENT_CONTRACT_DIALOG':
      return {
        ...state,
        showNewClientContractDialog: action.payload
      }

    case 'SET_CLIENT_ADDITIONAL_EXPENSES':
      return {
        ...state,
        clientAdditionalExpensesSum: action.payload
      }

    case 'SET_TRANSPORTER1_ADDITIONAL_EXPENSES':
      return {
        ...state,
        transporter1AdditionalExpensesSum: action.payload
      }




    default:
      return state
  }
}

export default ECReducer
