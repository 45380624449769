import * as React from 'react';
import GoogleMapReact from "google-map-react";
import "./style.css";
import {useEffect} from "react";
import BXAPI from "../api.bitrix24";


export default function GoogleMapsRoute() {

   const [isDataReadyForMap, setIsDataReadyForMap] = React.useState(false);
   const [state, setState] = React.useState({});
   const [map, setMap] = React.useState({});
   const [mapIsLoaded, setMapIsLoaded] = React.useState(false);

   const [originInit, setOriginInit] = React.useState({});
   const [destinationInit, setDestinationInit] = React.useState({});

   useEffect(() => {

console.log('we are in useEffect with changed data: ')
      console.log('origin: ',originInit)
      console.log('destination: ',destinationInit)


      setIsDataReadyForMap(true)


      const google = window.google;

      if(google == null )
         return;

      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer();
      directionsRenderer.setMap(map);
      //const origin = { lat: 40.756795, lng: -73.954298 };
      const origin = originInit//{ lat: 52.5069312, lng: 13.1445537 };
      const destination = destinationInit//{ lat: 55.5815244, lng: 36.8251261 };
      //const destination = { lat: 41.756795, lng: -78.954298 };

      directionsService.route(
         {
            origin: origin,
            destination: destination,
            travelMode: google.maps.TravelMode.DRIVING
         },
         (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
               directionsRenderer.setDirections(result);
            } else {
               console.error(`error fetching directions ${result}`);
            }
         }
      );


   }, [originInit, destinationInit, mapIsLoaded]);

   useEffect(() => {
      //setState({currentLocation: { lat: 40.756795, lng: -73.954298 }});



      BXAPI();

      window.BX24.init(function() {

         const b24_access_token_data = window.BX24.getAuth()
         console.log('getAuth: ', b24_access_token_data)

         console.log('Инициализация завершена!', window.BX24.isAdmin());
         console.log('Placement info:', window.BX24.placement.info());

         const placementInfo = window.BX24.placement.info()

         if(placementInfo.placement === 'CRM_DYNAMIC_147_DETAIL_TAB') {

            const requestId = window.BX24.placement.info().options.ID.length > 0 ? window.BX24.placement.info().options.ID : '-1'

            const params = {
               'entityTypeId': 147,
               'id': requestId
            };

            window.BX24.callMethod(
               'crm.item.get',
               params,
               function(result)
               {
                  if(result.error())
                     console.log("Error: " + result.error());
                  else {
                     console.log('Result:',result.data());

                     const requestData = result.data().item;

                     const segment1Start = requestData.ufCrm7_1679575807;
                     const segment1End = requestData.ufCrm7_1679575956;
                     const segment2Start = requestData.ufCrm7_1681021457;
                     const segment2End = requestData.ufCrm7_1681021577;
                     const segment3Start = requestData.ufCrm7_1681058998;
                     const segment3End = requestData.ufCrm7_1681059114;
                     const segment4Start = requestData.ufCrm7_1681060573;
                     const segment4End = requestData.ufCrm7_1681060667;

console.log('segment1Start', segment1Start)
console.log('segment1End', segment1End)
console.log('segment2Start', segment2Start)
console.log('segment2End', segment2End)
console.log('segment3Start', segment3Start)
console.log('segment3End', segment3End)
console.log('segment4Start', segment4Start)
console.log('segment4End', segment4End)

                     let startCoord;
                     if(segment1Start !== null)
                        startCoord = segment1Start.substring(segment1Start.indexOf('|')+1 )

                     let endCoord;
                     if(segment4End !== null) {
                        endCoord = segment4End.substring(segment4End.indexOf('|') + 1)
                     }
                     else if ((segment3End !== null)) {
                        endCoord = segment3End.substring(segment3End.indexOf('|') + 1)
                     }
                     else if ((segment2End !== null)) {
                        endCoord = segment2End.substring(segment2End.indexOf('|') + 1)
                     }
                     else if ((segment1End !== null)) {
                        endCoord = segment1End.substring(segment1End.indexOf('|') + 1)
                     }



                     if(startCoord.indexOf('|') !== -1)
                        startCoord = startCoord.substring(0, startCoord.indexOf('|'))
                     if(endCoord.indexOf('|') !== -1)
                        endCoord = endCoord.substring(0, endCoord.indexOf('|'))

console.log('startCoord', startCoord)
console.log('endCoord', endCoord)


                     const originArray = startCoord.split(';')
                     const destinationArray = endCoord.split(';')

                     setOriginInit({ lat: Number.parseFloat(originArray[0]) , lng: Number.parseFloat(originArray[1]) })
                     setDestinationInit({ lat: Number.parseFloat(destinationArray[0]), lng: Number.parseFloat(destinationArray[1]) })

                     //setState({currentLocation: { lat: 40.756795, lng: -73.954298 }});
                  }
               }
            );

         }



      })

   }, []);


   const apiIsLoaded = (map, maps) => {


      setMap(map)
      setMapIsLoaded(true)

//       const google = window.google;
//
// console.log('======= We are in apiLoaded Function')
//
// console.log('map: ', map)
// console.log('originInit', originInit)
// console.log('destinationInit', destinationInit)
//
//       const directionsService = new google.maps.DirectionsService();
//       const directionsRenderer = new google.maps.DirectionsRenderer();
//       directionsRenderer.setMap(map);
//       //const origin = { lat: 40.756795, lng: -73.954298 };
//       const origin = originInit//{ lat: 52.5069312, lng: 13.1445537 };
//       const destination = destinationInit//{ lat: 55.5815244, lng: 36.8251261 };
//       //const destination = { lat: 41.756795, lng: -78.954298 };
//
//       directionsService.route(
//          {
//             origin: origin,
//             destination: destination,
//             travelMode: google.maps.TravelMode.DRIVING
//          },
//          (result, status) => {
//             if (status === google.maps.DirectionsStatus.OK) {
//                directionsRenderer.setDirections(result);
//             } else {
//                console.error(`error fetching directions ${result}`);
//             }
//          }
//       );
   };

   // if(isDataReadyForMap == false)
   //    return (<div>'Loading...'</div>);
   // else
      return (
         <div>
            <div style={{ height: "100vh", width: "100%" }}>

               {isDataReadyForMap === true &&
                  <GoogleMapReact
                     bootstrapURLKeys={{
                        key: "AIzaSyD6yi8e7oVYtVyZkVT39X_DnV6H1vMFgds"
                     }}
                     defaultCenter={{ lat: 40.756795, lng: -73.954298 }}
                     defaultZoom={10 }
                     center={state.currentLocation}
                     yesIWantToUseGoogleMapApiInternals
                     onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
                  />
               }
            </div>
         </div>
      );
}









//
// class GoogleMapsRoute extends Component {
//    constructor(props) {
//       super(props);
//
//       this.state = {
//          currentLocation: { lat: 40.756795, lng: -73.954298 }
//       };
//    }
//
//    render() {
//       const apiIsLoaded = (map, maps) => {
//
//          const google = window.google;
//
//          const directionsService = new google.maps.DirectionsService();
//          const directionsRenderer = new google.maps.DirectionsRenderer();
//          directionsRenderer.setMap(map);
//          //const origin = { lat: 40.756795, lng: -73.954298 };
//          const origin = { lat: 52.5069312, lng: 13.1445537 };
//          const destination = { lat: 55.5815244, lng: 36.8251261 };
//          //const destination = { lat: 41.756795, lng: -78.954298 };
//
//          directionsService.route(
//             {
//                origin: origin,
//                destination: destination,
//                travelMode: google.maps.TravelMode.DRIVING
//             },
//             (result, status) => {
//                if (status === google.maps.DirectionsStatus.OK) {
//                   directionsRenderer.setDirections(result);
//                } else {
//                   console.error(`error fetching directions ${result}`);
//                }
//             }
//          );
//       };
//       return (
//          <div>
//             <div style={{ height: "100vh", width: "100%" }}>
//                <GoogleMapReact
//                   bootstrapURLKeys={{
//                      key: "AIzaSyD6yi8e7oVYtVyZkVT39X_DnV6H1vMFgds"
//                   }}
//                   defaultCenter={{ lat: 40.756795, lng: -73.954298 }}
//                   defaultZoom={10   }
//                   center={this.state.currentLocation}
//                   yesIWantToUseGoogleMapApiInternals
//                   onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
//                />
//             </div>
//          </div>
//       );
//    }
// }
// export default GoogleMapsRoute;