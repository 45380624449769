import * as React from 'react';
import Map from "../components/map";
//import GoogleMaps from "../components/GoogleMaps";
import GoogleMapsRoute from "../components/GoogleMapsRoute";


function Home() {


   return (
      <>

         <GoogleMapsRoute />

         {/*<Map />*/}

         {/*<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d67346.96744615292!2d27.57373675214515!3d53.8884836717457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sby!4v1678827074054!5m2!1sen!2sby" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>*/}
      </>
  )
}

export default Home


